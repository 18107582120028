/* global angular, gData, config, moment */

'use strict';

angular.module('managerApp').controller('DefaultLeftSidebarController', (
        $rootScope, $timeout, $scope, $location, $mdSidenav, $cookies,
        toast, Auth, User, procedureService, refunds, $mdDialog, $filter, terminals) => {

//------------------------------------------------------------------------------

    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;

    $scope.isPartnerSession = config.isPartnerSession;

    $scope.offlineTerminalsNb = $rootScope.offlineTerminalsNb || 0;
    $scope.departements = gData.departements;
    $scope.refundsCount = null;
    $scope._type = getCurrentPath();

//------------------------------------------------------------------------------

    $rootScope.$on('open-taxes-calc', function (e, data) {
        $scope.showTaxesCalcPopup(data);
    });

//------------------------------------------------------------------------------

    $scope.$on('toggleMenu', function (event, args) {
        $mdSidenav('left').toggle();
    });

    $scope.openSidenav = () => {
        $mdSidenav('left-sidebar').toggle();
    };

    $scope.$on('$routeChangeStart', function (event, next, current) {
        $scope._type = getCurrentPath();
    });

    $scope.showLogoutPopup = ($event) => {
        $mdDialog.show({
            targetEvent: $event,
            parent: angular.element(document.body),
            templateUrl: 'components/left-sidebar/templates/logout.html',
            controller: ($scope, $location, $mdDialog) => {
                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        $location.path('/logout');
                    }
                    $mdDialog.cancel();
                };
            }
        });
    };

    $scope.showTaxesCalcPopup = (data = null) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            templateUrl: 'components/left-sidebar/templates/taxes-simulator.html',
            clickOutsideToClose: false,
            escapeToClose: false,
            hasBackdrop: false,
            disableParentScroll: false,
            locals: {
                fuelTypes: $scope.fuelTypes,
                vehicleTypes: $scope.vehicleTypes,
                departements: $scope.departements,
                data: data
            },
            controller: ($scope, $mdDialog, toast, fuelTypes, vehicleTypes, departements, data) => {
                $scope.vehicleTypes = vehicleTypes;
                $scope.fuelTypes = fuelTypes;
                $scope.departements = departements;
                $scope.realDiffTaxes = null;
                $scope.dataTaxe = {};

                $scope.calcTaxes = () => {

                    $scope.dataTaxe.demarche = '2';
                    $scope.dataTaxe.dateMiseEnCirculation = $filter('date')($scope.dataTaxe._dateMiseEnCirculation, 'dd/MM/yyyy');

                    let data = Object.assign({}, $scope.dataTaxe);
                    delete data._dateMiseEnCirculation;
                    procedureService.calculateTaxes($scope.dataTaxe).then((res) => {
                        $scope.taxes = res.taxes;
                        if ($scope.currentTaxes) {
                            $scope.diffTaxes = Math.round(($scope.taxes.y6.value - $scope.currentTaxes.y6) * 100) / 100;
                        }
                        $timeout(() => $scope.$apply());
                    }).catch((err) => {
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                    });
                };

                if (data) {
                    $scope.procedureId = data.procedureId;
                    $scope.verificationMode = true;
                    for (let f in data.data) {
                        let value = data.data[f];
                        if (f === 'taxes') {
                            $scope.currentTaxes = value;
                        } else if (f === 'dateMiseEnCirculation') {
                            $scope.dataTaxe['_dateMiseEnCirculation'] = moment(value, 'DD/MM/YYYY').toDate();
                        } else {
                            $scope.dataTaxe[f] = value;
                        }
                    }
                    $scope.calcTaxes();
                }

                $scope.dispatchCreatePayment = () => {
                    $rootScope.$broadcast('create-payment', {
                        procedureId: $scope.procedureId,
                        amount: -1 * $scope.realDiffTaxes,
                        motif: 'supplement-taxes'
                    });
                };

                $scope.dispatchCreatePartielTaxesRefund = () => {
                    $rootScope.$broadcast('create-partiel-taxes-refund', {
                        procedureId: $scope.procedureId,
                        amount: $scope.realDiffTaxes,
                        type: 'partiel'
                    });
                };

                $scope.callUpdateProcedureTaxes = () => {
                    let confirm = $mdDialog.confirm()
                            .title('Mettre à jour les taxes')
                            .textContent('Voulez-vous vraiment mettre à jour les taxes de la démarche ?')
                            .targetEvent(event)
                            .ok('Oui')
                            .cancel('Non')
                            .theme('confirm')
                            .multiple(true);
                    $mdDialog.show(confirm, {multiple: true}).then(() => {
                        procedureService.updateTaxes($scope.procedureId).then((res) => {
                            $scope.realDiffTaxes = res.diffTaxes;
                            toast.show('Les taxes de la démarche ont été bien mises à jour.', 'success');
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    }, () => {
                        // cancelled
                    });
                };

                $scope.closeDialog = () => {
                    $mdDialog.hide();
                };
            }
        });
    };

    $scope.showFIVPopup = () => {
        $mdDialog.show({
            parent: angular.element(document.body),
            templateUrl: 'components/left-sidebar/templates/get.fiv.html',
            controller: ($scope, $mdDialog, toast) => {
                $scope.link = null;
                $scope.sendData = () => {
                    let data = {
                        registrationNum: $scope.registrationNum,
                        identificationNum: $scope.identificationNum
                    };
                    if (typeof $scope.name === 'string' && $scope.name.length > 2) {
                        data.name = $scope.name;
                    } else if (typeof $scope.company === 'string' && $scope.company.length > 2) {
                        data.company = $scope.company;
                    }
                    procedureService.getFIV(data).then((result) => {
                        if (result && result.link) {
                            $scope.link = result.link;
                        }
                        $timeout(() => {
                            $scope.$apply();
                        });
                    }).catch((err) => {
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                    });
                };
                $scope.closeDialog = () => {
                    $mdDialog.hide();
                };
            }
        });
    };

    $scope.reloadProfile = () => {
        User.get().$promise.then((user) => {
            if (user) {
                Auth.setCurrentUser(user);
                $rootScope.currentUser = user;
                $cookies.put(config.auth.me, JSON.stringify(user));
                setTimeout(() => {
                    setPermissions();
                }, 50);
            }
        }).catch((err) => {
            //
        });
    };

    //--------------------------------------------------------------------------

    function setPermissions(user = null) {

        $scope.hasDashboardPermission = Auth.hasPermissions('DASHBOARD', user);
        $scope.hasUsersPermission = Auth.hasPermissions('USERS:GET', user);
        $scope.hasUsersCreationPermission = Auth.hasPermissions('USERS:POST', user);
        $scope.hasClientsPermission = Auth.hasPermissions('CLIENTS:GET', user);
        $scope.hasClientsCreationPermission = Auth.hasPermissions('CLIENTS:POST', user);

        $scope.hasRefundsPermission = Auth.hasPermissions('REFUNDS:GET', user);

        $scope.hasCertificatSituationAdministrativePermission = Auth.hasPermissions('SERVICE_CERTIFICAT_SITUATION_ADMINISTRATIVE:GET', user);
        $scope.hasCertificatImmatriculationPermission = Auth.hasPermissions('SERVICE_CERTIFICAT_IMMATRICULATION:GET', user);
        $scope.hasCertificatImmatriculationWWPermission = Auth.hasPermissions('SERVICE_CERTIFICAT_IMMATRICULATION_WW:GET', user);
        $scope.hasCertificatImmatriculationVIPermission = Auth.hasPermissions('SERVICE_CERTIFICAT_IMMATRICULATION_VI:GET', user);
        $scope.hasTaxesSimulatorPermission = Auth.hasPermissions('SERVICE_CERTIFICAT_IMMATRICULATION:PUT', user);
        $scope.hasDeclarationAchatPermission = Auth.hasPermissions('SERVICE_DECLARATION_ACHAT:GET', user);
        $scope.hasDeclarationCessionPermission = Auth.hasPermissions('SERVICE_DECLARATION_CESSION:GET', user);
        $scope.hasVenteEnLignePermission = Auth.hasPermissions('SERVICE_VENTE_EN_LIGNE:GET', user);
        $scope.hasPlaquesImmatriculationPermission = Auth.hasPermissions('SERVICE_PLAQUES_IMMATRICULATION:GET', user);
        $scope.hasChangementAdressePermission = Auth.hasPermissions('SERVICE_CHANGEMENT_ADRESSE:GET', user);
        $scope.hasDuplicataPermission = Auth.hasPermissions('SERVICE_DUPLICATA:GET', user);

        $scope.hasProceduresPermissions =
                $scope.hasCertificatSituationAdministrativePermission ||
                $scope.hasCertificatImmatriculationPermission ||
                $scope.hasDeclarationAchatPermission ||
                $scope.hasDeclarationCessionPermission ||
                $scope.hasDuplicataPermission ||
                $scope.hasVenteEnLignePermission ||
                $scope.hasPlaquesImmatriculationPermission ||
                $scope.hasChangementAdressePermission ||
                $scope.hasCertificatImmatriculationWWPermission ||
                $scope.hasCertificatImmatriculationVIPermission;

        $scope.hasTerminalsPermission = Auth.hasPermissions('TERMINALS', user);
        $scope.hasBoxesPermission = Auth.hasPermissions('BOXES:GET', user);

        $scope.hasMessagesPermission = Auth.hasPermissions('MESSAGES:GET', user);
        $scope.hasNotificationsPermission = Auth.hasPermissions('NOTIFICATIONS:GET', user);
        $scope.hasSmsPermission = Auth.hasPermissions('SMS:GET', user);
        $scope.hasContactsPermission = $scope.hasMessagesPermission ||
                $scope.hasNotificationsPermission || $scope.hasSmsPermission;

        $scope.hasPacksPermission = Auth.hasPermissions('PACKS:GET', user);
        $scope.hasDiscountsPermission = Auth.hasPermissions('DISCOUNTS:GET', user);
        $scope.hasPricesPermission = Auth.hasPermissions('PRICES:GET', user);
        $scope.hasAdsPermission = Auth.hasPermissions('ADS:GET', user);

        $scope.hasVariousItemsPermission =
                $scope.hasPacksPermission ||
                $scope.hasAdsPermission ||
                $scope.hasDiscountsPermission ||
                $scope.hasPricesPermission;

        $scope.hasHelpPermission = Auth.hasPermissions('HELP:GET', user);
        $scope.hasFraudsPermission = Auth.hasPermissions('FRAUDS:GET', user);

        $scope.hasTabletsPermission = Auth.hasPermissions('TABLETS:GET', user);
        $scope.hasWebAppsPermission = Auth.hasPermissions('WEBAPPS:GET', user);

        if ($scope.hasTerminalsPermission) {
            /*terminals.getOffline().then((status) => {
             if (status.offline === true) {
             toast.show('Il semble que, actuellement, le traitement instantanné soit désactivé' +
             ' sur toutes les bornes.', 'error', true);
             }
             });*/
        }

        if ($scope.hasProceduresPermissions) {
            procedureService.getCounts().then((data) => {
                const counts = data.counts || [];
                for (let i = 0; i < counts.length; i++) {
                    const row = counts[i];
                    switch (row.type) {
                        case 'creation-compte-professionnel':
                            $scope.ccp = row.count;
                            break;
                        case 'certificat-immatriculation':
                            $scope.cim = row.count;
                            break;
                        case 'certificat-immatriculation-ww':
                            $scope.ciww = row.count;
                            break;
                        case 'certificat-immatriculation-vi':
                            $scope.civi = row.count;
                            break;
                        case 'vente-en-ligne':
                            $scope.vel = row.count;
                            break;
                        case 'declaration-achat':
                            $scope.da = row.count;
                            break;
                        case 'declaration-cession':
                            $scope.dc = row.count;
                            break;
                        case 'certificat-situation-administrative':
                            $scope.csa = row.count;
                            break;
                        case 'plaques-immatriculation':
                            $scope.pim = row.count;
                            break;
                        case 'changement-adresse':
                            $scope.cad = row.count;
                            break;
                        case 'duplicata':
                            $scope.dup = row.count;
                            break;
                        case 'never-handled':
                            $scope.neverHandled = row.count;
                            break;
                    }
                }
                $scope.currentProcedure = data.currentProcedure;
                if (typeof $scope.currentProcedure !== 'undefined') {
                    $scope.currentProcedure.path = 'procedures/' + $scope.currentProcedure.type + '/' +
                            $scope.currentProcedure._id;
                }
                $scope.$apply();
            });
        }

        if ($scope.hasRefundsPermission) {
            if (typeof $cookies.get('refundsCount') !== 'undefined') {
                $scope.refundsCount = $cookies.get('refundsCount');
            } else {
                refunds.getCount().then((refundsCount) => {
                    if (refundsCount && refundsCount !== '0') {
                        $scope.refundsCount = refundsCount;
                        let expireDate = moment().add(15, 'm').toDate();
                        $cookies.put('refundsCount', refundsCount, {'expires': expireDate});
                    }
                });
            }
        }

        setTimeout(() => {
            $scope.$apply();
        }, 100);

    }

    function getCurrentPath() {
        let parts = $location.path().split('/'),
                index = parts.length - 1;
        if (parts.length === 4) {
            index = parts.length - 2;
        }
        if (parts[index] === 'partners') {
            return parts[index - 1] + '-' + parts[index];
        }
        return parts[index];
    }

    function getVehicleTypes() {
        let dataType = JSON.parse(localStorage.getItem("vehicleTypes"));
        if (!dataType) {
            procedureService.getVehicleTypes().then((data) => {
                localStorage.setItem('vehicleTypes', JSON.stringify(data));
                $scope.vehicleTypes = data;
                $scope.$apply();
            }).catch((err) => {
                $scope.error = err;
            });
        } else {
            $scope.vehicleTypes = dataType;
        }
    }

    function getFuelTypes() {
        let dataType = JSON.parse(localStorage.getItem("fuelTypes"));
        if (!dataType) {
            procedureService.getFuelTypes().then((data) => {
                localStorage.setItem('fuelTypes', JSON.stringify(data));
                $scope.fuelTypes = data;
                $scope.$apply();
            }).catch((err) => {
                $scope.error = err;
            });
        } else {
            $scope.fuelTypes = dataType;
        }
    }

    //--------------------------------------------------------------------------    

    setPermissions();

    if (Auth.isLoggedIn() === true) {
        getFuelTypes();
        getVehicleTypes();
    }

});